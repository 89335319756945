<template>
  <Details
    title="customer user"
    resource="customerProfiles"
    :base-path="basePath"
    :request-params="{customerId: customerId}"
  >
    <template v-slot:default="detailsProps">
      <UserForm
        :initialValues="initialValues"
        :admin="isAdmin"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import UserForm from '@/views/auth/customers/users/UserForm';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import Details from '@/components/auth/details/Details';
  import {AUTH_ALIAS, ROLES} from '@/components/auth/constants';

  export default {
    name: 'UsersCreate',
    components: {
      UserForm,
      Details
    },
    mixins: [ModalNavigation],
    computed: {
      customerId: function () {
        return this.$route.params.customerId;
      },
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      },
      isAdmin: function () {
        return this.$route.name.includes('admins');
      },
      initialValues: function() {
        if (this.isAdmin) {
          return {
            appRoles: {
              [AUTH_ALIAS]: ROLES.CA,
            },
            mfaRequired: true,
          }
        }
        return {
          appRoles: {
            [AUTH_ALIAS]: ROLES.NO_ROLE,
          },
        };
      },
    },
  };
</script>
